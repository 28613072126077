.main-header {
  display: flex;
  align-items: center;
  gap: 40px;
  padding: 20px 0;
}

.main-header__search-container {
  flex: 1;
}

.main-header__links {
  display: flex;
  padding: 10px 0;
  gap: 16px;
  align-items: baseline;
}
