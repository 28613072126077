$font-family: 'Rubik';

@mixin link-styles {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  cursor: pointer;

  span {
    line-height: 1;
    color: #333;
    font-family: $font-family;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
  }
}

@mixin cart-length-badge {
  width: 9px;
  height: 9px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background-color: #f26b26;
  top: -2px;
  color: #fff;
  text-align: center;
  font-family: 'Open Sans';
  font-size: 7px;
  font-style: normal;
  line-height: normal;
  font-weight: 600;
}

.main-header__user-profile-link {
  @include link-styles;
  min-width: 48px;
}

.main-header__user-profile-link__mobile {
  @include link-styles;
  min-width: 24px;
}

.main-header__favorite-link {
  @include link-styles;
  min-width: 48px;
}

.main-header__favorite-link__mobile {
  @include link-styles;
  min-width: 24px;
}

.main-header__cart-link {
  @include link-styles;
  min-width: 48px;
  position: relative;
}

.main-header__cart-link__mobile {
  @include link-styles;
  position: relative;
  min-width: 24px;
}

.main-header__menu-button {
  @include link-styles;
  min-width: 24px;
  display: flex;
  z-index: 1000;
  span {
    font-size: 7px;
    letter-spacing: 1.645px;
  }
}

.cart-link__length_badge {
  @include cart-length-badge;
  right: 10px;
}

.cart-link__length_badge-mobile {
  @include cart-length-badge;
  right: 0;
}

.cart-item {
  display: flex;
  gap: 10px;

  img {
    align-self: stretch;
    border-radius: 4px;
  }

  .cart-item-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 6px;
  }
}

.divider {
  width: 100%;
  border-top: 1px solid var(--secondary-border-1);
}

.more-products {
  width: 100%;
  text-align: center;
  cursor: pointer;
}

.buttons {
  display: flex;
  gap: 10px;

  button:nth-child(1) {
    width: 85px;
  }

  button:nth-child(2) {
    width: 125px;
  }
}
